import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {Link} from 'react-router-dom';

import EastIcon from '@mui/icons-material/East';

function IndexContent() {

    return(
        <>


        <Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
   
                       
                           <Grid item sm={5} className="abt_img_wrap">
                           <Card className="abt_img"><img src="images/about_us.jpg" variant="rounded" /></Card>                              
                                   
                           </Grid>
   
                           <Grid item sm={7}>                           
                               <Typography variant="h6" p={0} sx={{display: 'flex'}}>
                                    <Typography variant="body" pr={2}><img src="images/icon.png" /></Typography>
                                    <Typography variant="body">About CHEAP WEB GURU</Typography>
                                </Typography>

                               <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Cheap Web Guru is one of 
the best<Typography variant="body" className="yellowTxt" fontWeight="normal"> freelance web solutions
in India.</Typography></Typography>
                           <Typography variant="body2" py={2}>Cheapwebguru.com is one of the best freelance web solutions in India. Our web design portfolio is huge. We have 10 years old experience freelance web solutions involved in web design with development, digital ad marketing and promotion of websites and mobile application. We can update the list of projects from time to time to show you in our portfolio page. Our freelance web solutions Cheapwebguru.com involves implementation of small business to large business build for respect customer with variety of business domains. Inside the following pages, you will be able to view our past responsive web design solutions, logo design work, mobile app examples, backend PHP programming examples, cheap rated and high demand ecommerce project cost and much more. </Typography>
                           <Typography variant="body2" pb={2}>We offer services like responsive web designing and development, full control PHP backend panel, logo designing, CMS web solutions, ecommerce web projects, digital ads marketing, mobile app development, Search Engine Optimization with Google, New domain registration and Web hosting services to our clients.</Typography>
                           <Typography variant="body2" pb={2}>The services range from small to large sized budget so that the client will not have to spend much on advertising.</Typography>
                           
                           </Grid>
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               {/* serviceRow */}

               <Grid className="serviceRow">
                   <Container>
                       <Grid container pb={2}>

                       <Grid item sm={12}>                           
                               <Typography variant="h6" p={0} sx={{display: 'flex'}}>
                                    <Typography variant="body" pr={2}><img src="images/icon.png" /></Typography>
                                    <Typography variant="body">What We do</Typography>
                                </Typography>

                               <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">We Provide Exclusive Service</Typography>
                               <Typography variant="h4" className="yellowTxt">For Your Business</Typography>
                           </Grid>
                           
                    </Grid>

                    <Grid container spacing={4} className="serviesRow">

                        <Grid item md={3} sm={6} textAlign="center">
                            <Card  className="box">
                                <img src="images/web_design.png" className="img-fluid"  />
                                
                                <CardContent className="CardContent">
                                <img src="images/web_design_icon.png" className="img-icon"  />
                                    <Typography variant="h5" textAlign="center" className="blueTxt">Branding Design</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Responsive Web Design Through HTML5, CSS3, Boostrap</Typography>
                                    <Link to="/services/brand-designing"><Button endIcon={<EastIcon />} >Read more</Button></Link>                                    
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} textAlign="center">
                            <Card  className="box">
                                <img src="images/web_development.png" className="img-fluid"  />
                                
                                <CardContent className="CardContent">
                                <img src="images/web_design_icon.png" className="img-icon"  />
                                    <Typography variant="h5" textAlign="center" className="blueTxt">Website Design & Development</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Our experts have the know-how to create websites to convert visitors into buyers!</Typography>
                                    <Link to="/services/web-development"><Button endIcon={<EastIcon />}>Read more</Button></Link>                                    
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} textAlign="center">
                            <Card  className="box">
                                <img src="images/mobile_app_development.png" className="img-fluid"  />
                                
                                <CardContent className="CardContent">
                                <img src="images/web_design_icon.png" className="img-icon"  />
                                    <Typography variant="h5" textAlign="center" className="blueTxt">Mobile App Development</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Looking to give high quality services to your customers from mobile.</Typography>
                                    <Link to="/services/app-developmen"><Button endIcon={<EastIcon />}>Read more</Button></Link>                                  
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} textAlign="center">
                            <Card  className="box">
                                <img src="images/digital_marketing_services.png" className="img-fluid"  />
                                
                                <CardContent className="CardContent">
                                <img src="images/web_design_icon.png" className="img-icon"  />
                                    <Typography variant="h5" textAlign="center" className="blueTxt">Digital Marketing Services</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Google Advertising, Social Media Marketing, Email Marketing, SMS Marketing.</Typography>
                                    <Link to="/services/digital-marketing"><Button endIcon={<EastIcon />}>Read more</Button></Link>                                   
                                </CardContent>                        
                            </Card>
                        </Grid>

                        {/* <Grid item sm={3} textAlign="center">
                            <Card>
                                <img src="images/web_design.png"  />
                                <CardContent style={{padding: '10px'}}>
                                    <Typography variant="h5" textAlign="center" className="blueTxt">Design Branding</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Responsive Web Design Through HTML5, CSS3, Boost</Typography>
                                    <Button>Read more</Button>                                    
                                </CardContent>                        
                            </Card>
                        </Grid> */}

                        

                       


   
                          
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>
         
         
         
   
         
   
   
   
        
         </>

    );
}

export default IndexContent;