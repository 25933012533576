
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function BrandDesigningContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Brand <Typography variant="body">Designing</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
   
   
                           <Grid item sm={12}>                           
                              
                           <Typography variant="body2" py={2} >
                            
                            
                            
                           We are Cheap Web Guru Solutions, a creative design agency based out of Kolkata, India, specializing in branding and logo design services. We have been helping businesses of all sizes and industries to create strong identities with our award-winning branding services.
<br /><br />

Our experienced and talented team of branding professionals is passionate about creating powerful brands and logos that will help you stand out from the competition. We are dedicated to delivering exceptional results that will help you reach your business goals.
<br /><br />

From logo design to brand identity, we offer a wide range of services to help you create the perfect brand for your business. We specialize in the latest industry trends, such as responsive website design, logo design, and brand identity design, to ensure your brand looks great on any device.
<br /><br />

We understand that branding is an integral part of any business and can make or break it. That’s why we take the time to get to know you and your business, so we can create a unique brand that reflects your values and goals.

                           
                           
                           
                           </Typography>

                           
                           </Grid>
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                       <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Our services include:</Typography>
                                    </Grid>


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/logo-design.png" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Logo Design</Typography>
                                        <Typography variant="body2">Let us help you create a unique and memorable logo that will capture the attention of your target audience.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                   
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/brand-identity.png"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                    <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Brand Identity</Typography>
                                        <Typography variant="body2">We can help you create a powerful and consistent brand identity that will help you stand out from the competition.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>

               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/responsive-website-design.png" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Responsive Website Design </Typography>
                                        <Typography variant="body2">We specialize in designing responsive websites that look great on any device.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                   
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/social-media-design.png"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                    <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Social Media Design </Typography>
                                        <Typography variant="body2">We can design custom graphics, banners, and content to help you create an effective social media presence. </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container>

                        
                       <Grid item xs={12}>
                        <Typography variant="body2" pb={2}>We believe that a unique brand identity should be the foundation of any business and we strive to create the perfect one for our clients. Our team of experienced professionals take into account the vision and values of the business to develop a brand identity that stands out from the rest.<br /><br />
Our team of experienced designers is committed to creating the perfect brand for your business. We understand how important branding is and how it can impact your business. That’s why we take the time to get to know you and your business, so we can create a unique and powerful brand that will help you reach your goals.<br /><br />

If you are looking for a branding and design agency to help you create a powerful and consistent brand, then look no further than Cheap Web Guru Solutions. Contact us today for more information about our services and to get started!
</Typography>
                    </Grid>
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default BrandDesigningContent;