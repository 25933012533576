import React, {useEffect}  from 'react';

import WebDevelopmentContent from "../content/WebDevelopmentContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";
import {Helmet} from 'react-helmet';

function WebDevelopment() {

    useEffect(()=>{
        window.scroll({top: 0, behavior: 'smooth'});
    },[]);
    return(
        <>
        <Helmet>
            <title>Cheap Web Guru Solutions: Website Development</title>
        </Helmet>
        <Helmet>
            <meta name="keywords" content="web development, development company, web design, web development in india, , web development in kolkata"/>
        </Helmet>
        <Helmet>    
            <meta name="description" content="Cheap Web Guru Solutions is a 10-year-old digital services company specializing in web development, app development, web design, digital marketing. Founded in 2015, Cheap Web Guru Solutions has grown to become a leader in the industry, providing innovative solutions to businesses and organizations of all sizes." />
        </Helmet>
        <Navbar />
        <WebDevelopmentContent />
        <Footer />        
        </>
    );
}

export default WebDevelopment;