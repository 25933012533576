
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function HireusContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Hire <Typography variant="body">us</Typography></Typography>
</Grid>
    

   <section className="midWrapper">
        <Grid pt={5} pb={5}>
            <Container>
                <Grid container spacing={5}>   
                    <Grid item sm={12}>                           
                        <Typography variant="body2" py={2} >At Cheap Web Guru Solutions, we provide a wide range of web development, app development, and digital marketing services to help businesses reach their goals. Our team of talented professionals have years of experience in the industry and are experts in their respective fields. We are committed to providing our clients with the best solutions and services at competitive prices.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Grid>



        <Grid className="blueBg WebDevelopmentServices " pt={5} pb={5}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item sm={12}>
                        <Grid container spacing={4}>
                            <Grid item sm={4} xs={12}>
                                <img src="images/Web-Development-Services.png" className="img_fluid_r"  />
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">Web Development Services</Typography>
                                <Typography variant="body2">We offer an extensive range of web development services to help you create an interactive and engaging website for your business. Our services include web design and development, e-commerce development, content management systems, and custom web development. We have experience in building websites with various content management systems, such as WordPress, PHP, Laravel and React Js. We also provide website maintenance and support to ensure that your website is always up to date.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>

        <Grid className="AppDevelopmentServices " pt={5} pb={5}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item sm={12}>
                        <Grid container spacing={4}>                           
                            <Grid item sm={8} xs={12}>
                                <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">App Development Services</Typography>
                                <Typography variant="body2">We offer app development services to help you create an app for your business. Our team of experienced professionals will work with you to create an app that meets your business needs. We specialize in creating mobile apps for iOS and Android platforms and can also develop cross-platform apps. We also provide app maintenance and support services to ensure that your app is always up to date.</Typography>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <img src="images/App-Development-Services.png" className="img_fluid_r"  />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>


        <Grid className="blueBg DigitalMarketingServices" pt={5} pb={5}>
            <Container>
                <Grid container spacing={5}>
                    <Grid item sm={12}>
                        <Grid container spacing={4}>
                            <Grid item sm={4} xs={12}>
                                <img src="images/Digital-Marketing-Services.png" className="img_fluid_r"  />
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">Digital Marketing Services</Typography>
                                <Typography variant="body2">We offer a range of digital marketing services to help you reach your target audience. Our services include search engine optimization (SEO), pay-per-click advertising, social media marketing, and email marketing. We understand the importance of having an effective and comprehensive digital marketing strategy, and we can help you create one that meets your business goals. </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>






               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                       <Grid item sm={6} xs={12}>
                            <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">Why Hire Us?</Typography>
                            <Typography variant="body2" pb={2}>At Cheap Web Guru Solutions, we are committed to providing our clients with the best solutions and services at competitive prices. We have years of experience in the industry and are experts in web development, app development, and digital marketing. Our team of experienced professionals will work with you to create a custom solution that meets your business needs. </Typography>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">Contact Us</Typography>
                            <Typography variant="body2" pb={2}>If you are looking for web development, app development, or digital marketing services, contact us today to get started. Our team of experienced professionals is here to help you reach your goals. Contact us by phone or email to get a free consultation.  </Typography>
                        </Grid>              
                           
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default HireusContent;