
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function DigitalMarketingContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Digital  <Typography variant="body">Marketing</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
   
   
                           <Grid item sm={12}>                           
                              
                           <Typography variant="body2" py={2} >At Cheap Web Guru Solutions, our mission is to help businesses of all sizes to maximize their online presence with an effective digital marketing strategy. With years of experience, our team of dedicated professionals provide a variety of services, including SEO, PPC, Social Media, Content Marketing, and more. 
                        <br /><br />We understand that the success of your business depends on the success of your digital marketing strategy. That's why our team is dedicated to providing you with the best quality services, tailored to your unique needs and goals. With us, you can rest assured that your digital marketing efforts are in good hands.
                           </Typography>

                           
                           </Grid>
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                       <Grid item xs={12}>
                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">What We Do</Typography>
                        <Typography variant="body2">We offer a variety of services to help you reach your business goals. Our team of experts will analyze your current digital marketing strategy and develop a plan tailored to your needs. Our services include:</Typography>
                    </Grid>

                    


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/logo-design.png" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">SEO</Typography>
                                        <Typography variant="body2">Our team of experts will help you optimize your website for search engine rankings. We will work with you to develop a comprehensive SEO strategy, including keyword research, content optimization, link building, and more.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                   
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/brand-identity.png"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                    <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">PPC</Typography>
                                        <Typography variant="body2">Our team will help you develop an effective PPC campaign to reach potential customers. We will create targeted ads, track and analyze results, and adjust your campaign as needed.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>

               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/responsive-website-design.png" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Social Media </Typography>
                                        <Typography variant="body2">Our team will help you create and maintain a strong presence on social media. We will develop engaging content, cultivate relationships with customers, and track results.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                   
                                    <Grid item sm={4} xs={12}>
                                        <img src="../images/social-media-design.png"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                    <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Content Marketing </Typography>
                                        <Typography variant="body2">Our team will create and optimize content to attract and engage potential customers. We will help you create blog posts, infographics, videos, and more. </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container>

                        
                       <Grid item xs={12}>
                       <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Why Choose Us </Typography>
                       

                        <Typography variant="body2" pb={2}>When you choose Cheap Web Guru Solutions, you can rest assured that you are working with a team of experienced professionals who are dedicated to helping you succeed. We understand the importance of digital marketing and strive to provide the best quality services for our clients.<br /><br />
                        We are committed to providing high-quality services at an affordable price. Our team will work with you to develop a comprehensive digital marketing strategy tailored to your needs and budget.<br /><br />
                        We believe in providing our clients with the best customer service possible. Our team is here to answer any questions you may have and provide you with the resources you need to succeed.
</Typography>

                    <Typography variant="h5" fontWeight="bold" pb={0} className="blueTxt">Contact Us </Typography>

                    <Typography variant="body2" pb={2}>If you are ready to take your digital marketing strategy to the next level, contact Cheap Web Guru Solutions today. Our team of professionals will work with you to develop a comprehensive strategy tailored to your unique needs and goals. We look forward to helping you succeed!</Typography>

                    </Grid>
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

               

        </>
    );
}

export default DigitalMarketingContent;