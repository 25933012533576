
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box';


import {Link} from 'react-router-dom';

import EastIcon from '@mui/icons-material/East';

function WebhostingContent() {

    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
    <Typography variant="h2" fontWeight="bold">Our <Typography variant="body">Domain & web hosting</Typography></Typography>
</Grid>
    

   <section className="midWrapper">

                <Grid className="serviceRow">
                   <Container>
                    
                    <Grid container spacing={4} className="serviesRow">

                        <Grid item md={4} sm={6} textAlign="center">
                            <Card  className="box">
                                
                                
                                <CardContent className="CardContent">
                                    <Typography variant="h5" textAlign="center" className="blueTxt">BASIC PLAN</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">$8/month</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">20GB Disk Space</Typography>
                                    <Link to="#"><Button endIcon={<EastIcon />} >Read more</Button></Link>                                    
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={4} sm={6} textAlign="center">
                            <Card  className="box">
                                <CardContent className="CardContent">
                                    <Typography variant="h5" textAlign="center" className="blueTxt">SILVER PLAN</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">$12/month</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">unlimited Disk Space</Typography>
                                    <Link to="#"><Button endIcon={<EastIcon />} >Read more</Button></Link>                                    
                                </CardContent>                       
                            </Card>
                        </Grid>

                        <Grid item md={4} sm={6} textAlign="center">
                            <Card  className="box">
                                <CardContent className="CardContent">
                                    <Typography variant="h5" textAlign="center" className="blueTxt">VIP PLAN</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">$15/month</Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">unlimited Disk Space (Cloud Stoage)</Typography>
                                    <Link to="#"><Button endIcon={<EastIcon />} >Read more</Button></Link>                                    
                                </CardContent>                     
                            </Card>
                        </Grid>

                        
         
                   </Grid>
                   </Container>
               </Grid>
    </section>
               

        </>
    );
}

export default WebhostingContent;