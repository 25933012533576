
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';


import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import axios from "axios";
import validator from 'validator';

const mapstyle={
    border:0,
    width: '100%',
    height: '350px'
};

function ContactContent() {

    const errtxt={
        fontSize: 'small',
        color: 'red'
    };

    let [name,setName]=useState("");
    let [isname,setIsname]=useState(false);

    let [email,setEmail]=useState("");
    let [isemail,setIsemail]=useState(false);

    let [phone,setPhone]=useState("");
    let [isphone,setIsphone]=useState(false);

    let [subject,setSubject]=useState("");
    let [issubject,setIssubject]=useState(false);

    let [content,setContent]=useState("");
    let [iscontent,setIscontent]=useState(false);

    let [isbtn,setIsbtn]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [isalert,setIsalert]=useState(false);
    let [color,setColor]=useState("");

    let [statuscode,setStatuscode]=useState("");


    async function sendmessage(e) {
        var flag=1;

        if (name.length==0 || name.trim()=="") {
            setIsname(true);
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setIsemail(true);
            flag=0;
        }
        if (phone.length<10 || phone.trim()=="") {
            setIsphone(true);
            flag=0;
        }
        if (subject.length==0 || subject.trim()=="") {
            setIssubject(true);
            flag=0;
        }
        if (content.length==0 || content.trim()=="") {
            setIscontent(true);
            flag=0;
        }

        // alert(flag+':: '+name+', '+email+', '+phone+', '+subject+', '+content);

        if (flag==1) {
            // var fd=new FormData();
            // fd.append("name",name);
            // fd.append("email",email);
            // fd.append("phone",phone);
            // fd.append("subject",subject);
            // fd.append("content",content);

            e.preventDefault();

            // var resp=await axios.post("https://www.cheapwebguru.com/cwgapi/mailing.php",fd);

            // var resp=await axios.post("http://localhost:5000/api/mailing", { name, email, phone, subject, content });
            var resp=await axios.post("https://www.cheapwebguru.com/cwguru/api/mailing", { name, email, phone, subject, content });
            var data=resp.data;

            //alert(data.msg);

            setStatuscode(data.statuscode);
            setColor(data.color);

            setMsg(data.msg);
            setIsalert(data.sendmsg);
            setMsgcolor(data.msgcolor);

            if (data.statuscode==1) {
                // e.preventDefault();

                // var resp=await axios.post("https://www.cheapwebguru.com/cwgapi/mailing.php",fd);
                // var resp=await axios.post("http://localhost:5000/api/sendEmail",fd);
                // var resp2=await axios.post("https://cwcms.cloud/nodecwgapi/api/sendEmail", { email, subject, content });
                var resp2=await axios.post("https://cheapwebguru.com/cwguru/api/sendEmail", { email, subject, content });
                var data2=resp2.data;
                
                if (data2.statuscode==1) {
                    setMsg(data2.msg);
                    // setIsbtn(true);    
            
                    // setName("");
                    // setEmail("");
                    // setPhone("");
                    // setSubject("");
                    // setContent("");
                }

                setTimeout(()=>{
                    setStatuscode("");
                },4000);
            }

            

            
        }

    }


    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])
  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Contact <Typography variant="body">Us</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



    <Grid className="abt_row" pt={5} pb={5}>
        <Container>
            <Grid container spacing={5}>
                <Grid item sm={6}>
                <Grid item xs={12} mb={3}  textAlign="center" className="whiteBox">
                <Grid className="addIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                </Grid>
                


                    <Typography variant="h5" fontWeight="bold" pb={1} className="blueTxt">Registered Address</Typography>
                    <Typography variant="h6" fontWeight="bold" pb={0} className="">CHEAP WEB GURU SOLUTIONS</Typography>
                    <Typography variant="body2"  pb={0} className="">286, K.D Road, Uttarayan, Naihati, 743165, WB (INDIA)</Typography>
                </Grid> 

                    <Grid item xs={12} mb={3} textAlign="center" className="whiteBox">
                        <Grid className="addIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                            </svg>
                        </Grid>
                        <Typography variant="h5" fontWeight="bold" pb={1} className="blueTxt">Office  Address</Typography>
                        <Typography variant="h6" fontWeight="bold" pb={0} className="">CHEAP WEB GURU SOLUTIONS</Typography>
                        <Typography variant="body2"  pb={0} className="">8/4 Abhoy Ghosh Road, Mitra Para, Naihati, 743165, WB (INDIA)</Typography>
                    </Grid>


                    <Grid container spacing={3}>
                    
                   


                    <Grid item xs={6}  textAlign="center">
                    <Grid className="whiteBox">
                        <Grid className="addIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
</svg>

                        </Grid>
                        <Typography variant="h5" fontWeight="bold" pb={1} className="blueTxt">Email Us</Typography>
                        <Typography variant="body2" pb={0} className="">info@cheapwebguru.com</Typography>
                        </Grid>
                    </Grid> 

                    <Grid item xs={6}  textAlign="center">
                    <Grid className="whiteBox">
                        <Grid className="addIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
</svg>

                        </Grid>
                        <Typography variant="h5" fontWeight="bold" pb={1} className="blueTxt">Call Us</Typography>
                        <Typography variant="body2" pb={0} className="">+91-8910834910 / +91-8013561965</Typography>
                        </Grid>
                    </Grid> 

                </Grid>


                </Grid>


                <Grid item sm={6} pt={1}>
                    <Typography variant="h5" fontWeight="bold" textAlign="center" pb={3} className="blueTxt">Contact Us</Typography>

                    <Grid sx={{flexGrow: 1, textAlign: "center", fontWeight: 700}} mb={2}>{statuscode==1?<Grid pt={1} sx={{color: {color}}}>{msg}</Grid>:''}</Grid>

                    <Grid container spacing={3}>
                    
                    <Grid item xs={6} mt={1}>
                        <TextField  label="Your Name" variant="standard" placeholder="Your Name" fullWidth onChange={(ev)=>{
                            setName(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIsname(false);
                            }
                        }} value={name} />


                    {/* <input type="text" className="form-control" placeholder="Your Name" onChange={(ev)=>{
                            setName(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIsname(false);
                            }
                        }} /> */}
                        {isname?<div className="text text-danger" style={errtxt}>contact name required</div>:''}
                    </Grid> 

                    <Grid item xs={6} mt={1}>
                        <TextField  label="Your Email" variant="standard" placeholder="Your Email" fullWidth onChange={(ev)=>{
                            setEmail(ev.target.value);
                            if (validator.isEmail(email)) {
                                setIsemail(false);
                            }
                        }} value={email} />

                         {/* <input type="email" className="form-control" placeholder="Your Email" onChange={(ev)=>{
                            setEmail(ev.target.value);
                            if (validator.isEmail(email)) {
                                setIsemail(false);
                            }
                        }} /> */}
                        {isemail?<div className="text text-danger" style={errtxt}>contact email required</div>:''}
                    </Grid>

                     <Grid item xs={12} mt={1}>
                     <TextField id="standard-basic" label="Your phone" variant="standard" placeholder="Your phone" fullWidth onChange={(ev)=>{
                            setPhone(ev.target.value);
                            if (ev.target.value.length==10 && ev.target.value.trim()!="") {
                                setIsphone(false);
                            }
                        }} value={phone} />
                         {/* <input type="tel" minLength={10} maxLength={10} className="form-control" placeholder="Your Phone" onChange={(ev)=>{
                            setPhone(ev.target.value);
                            if (ev.target.value.length==10 && ev.target.value.trim()!="") {
                                setIsphone(false);
                            }
                        }} /> */}
                        {isphone?<div className="text text-danger" style={errtxt}>contact phone required</div>:''}
                    </Grid>  

                    <Grid item xs={12} mt={1}>
                    <TextField label="Your subject" variant="standard" placeholder="Your subject" fullWidth  onChange={(ev)=>{
                            setSubject(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIssubject(false);
                            }
                        }} value={subject} />
                        {/* <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={(ev)=>{
                            setSubject(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIssubject(false);
                            }
                        }} /> */}
                        {issubject?<div className="text text-danger" style={errtxt}>subject required</div>:''}
                    </Grid>

                    <Grid item xs={12} mt={1}>
                    <TextField  label="Your Message" variant="standard" placeholder="Your Message" fullWidth multiline rows={4} onChange={(ev)=>{
                            setContent(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIscontent(false);
                            }
                        }} value={content}  />

                    
          

                        {/* <textarea className="form-control" rows="5" placeholder="Message" onChange={(ev)=>{
                            setContent(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIscontent(false);
                            }
                        }} /> */}
                        {iscontent?<div className="text text-danger" style={errtxt}>message required</div>:''}
                    </Grid> 


                    <Grid item xs={12} textAlign="center" mt={1}>
                    <Button variant='contained' size="medium" onClick={sendmessage} disabled={isbtn}>Send message</Button>
                        
                    </Grid>

                </Grid>

                


                </Grid>

                
                
            </Grid>
            <Grid item xs={12} textAlign="center" sx={{border:0, width: '100%'}} mt={1}>
                <iframe style={mapstyle} src="https://www.google.com/maps/embed/v1/place?q=286+K.D+Road,+Naihati,+743165,+West+Bengal,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" width="600" height="450" frameborder="0" allowfullscreen="" loading="lazy"></iframe>
                </Grid>
        </Container>
    </Grid>




  




               </section>
               

        </>
    );
}

export default ContactContent;