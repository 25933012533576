
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { lineHeight } from "@mui/system";


function WebDevelopmentContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Web  <Typography variant="body">Development</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
   
                       
                           <Grid item sm={5}>
                                <Card className="abt_img"><img src="../../images/WebDevelopment.jpg" variant="rounded" /></Card>
                           </Grid>
   
                           <Grid item sm={7}>                           
                               {/* <Typography variant="h6" p={0} sx={{display: 'flex'}}>
                                    <Typography variant="body" pr={2}><img src="../../images/icon.png" /></Typography>
                                    <Typography variant="body">About CHEAP WEB GURU</Typography>
                                </Typography> */}

                               
                           <Typography variant="body2" pb={2} sx={{textAlign: 'justify'}} >We are a professional web development company with a long track record of success in the industry. At Cheap Web Guru Solutions, we specialize in crafting custom websites that are tailored to our clients' needs. Our team of experienced web developers are passionate about creating unique, beautiful, and functional websites that give your business the online presence it deserves.</Typography>

                           <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >We understand that the web development process is important for your business. That's why we strive to provide you with the highest quality web development services available. From the initial concept to the finished product, we work with you every step of the way to ensure that your website is exactly what you need. Our web developers have experience in a wide range of web technologies, such as HTML, CSS, JavaScript, PHP, MySQL, and more. We also use the latest web design trends and techniques to create a website that looks great and functions perfectly.</Typography>
                           </Grid>

                           <Grid item sm={7}>
                                <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >We create custom web applications and websites, from the ground up, that are user-friendly, secure, and visually appealing. Our developers are well-versed in the most popular web development languages, such as HTML, CSS, JavaScript, PHP, and more. We also use the most up-to-date frameworks and tools, ensuring your website is built with the latest technologies.</Typography>
                                <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >No matter what kind of website you need, we can help. We specialize in creating websites for small businesses, ecommerce stores, blogs, portfolios, and more. We also offer custom web development services to meet your unique needs. From custom forms and plugins to complex databases, we can build you a website that is tailored to your specific needs.</Typography>
                           </Grid>

                           <Grid item sm={5}>
                                <Card className="abt_img"><img src="../../images/WebDevelopment2.jpg" variant="rounded" /></Card>
                           </Grid>

                           <Grid item sm={12}>
                                <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >We understand that each business has its own unique needs. That's why we provide personalised web development services to help you achieve your desired outcome. Whether you need a simple website or a complex web application, our team of professionals can create a solution that fits your budget and timeline.</Typography>
                           </Grid>

                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <ul style={{ fontSize: 22, lineHeight: 1.8 }}>
                                            <li>Design and development of custom web applications</li>
                                            <li>Integration of existing applications into your web solution</li>
                                            <li>SEO-friendly website design and content</li>
                                            <li>Responsive web design for easy viewing on all devices</li>
                                            <li>Database design and development</li>
                                            <li>Social media integration</li>
                                            <li>Mobile app development</li>
                                        </ul>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                    <img src="../../images/WebDevelopment3.jpg"  className="img_fluid_r"  />
                                    </Grid>
                                    
                                    
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={12}>
                                <Grid container spacing={3}>
                                  
                                    
                                    <Grid item xs={12} sx={{textAlign: 'justify'}}>
                                        <Typography variant="body2" pb={2}>At our company, we understand that a website is much more than a collection of text and images. We take the time to understand your specific requirements and goals, so that we can create a custom solution that meets your needs.</Typography>

                                        <Typography variant="body2" pb={2}>We also provide ongoing maintenance and support for all our web development projects. Our team is always available to answer any questions or address any issues you may have.</Typography>
                                        <Typography variant="body2" pb={2}>If you're looking for a reliable, experienced web development team, look no further. Contact us today to discuss your web development project. We look forward to helping you create a unique and successful web solution.</Typography>

                                      


                                    </Grid>
                                </Grid>
                            </Grid> 
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default WebDevelopmentContent;