// import React  from "react";
// import Grid from '@mui/material/Grid';
// function Banner() {  
//     return(
//       <>
//       <Grid className="bannerWrapper"><img src="images/banner1.jpg" maxWidth="lg" /> </Grid>    
//       </>
//   );
// }

// export default Banner;


import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import Container from '@mui/material/Container';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Build Your Website',
    label2: 'at Lowest Price',
    label3: 'Our basic goal always has been to deliver an effective website - which offers a user a balance between visual appeal, informative content and user-friendly functionality. ',
    imgPath:
      '../../images/banner1.jpg',
  },
  {
    label: 'Web Development ',
    label2: '& Programming',
    label3: 'Web development refers to the building, creating, and maintaining of websites. It includes aspects such as web design, web publishing, web programming, database management, payment gateway integration, API integration. It is the creation of an application that works over the web application.',
    imgPath:
    '../../images/banner2.jpg',
  },
  {
    label: 'Make Your App',
    label2: 'Idea a Reality Now!',
    label3: 'Ready to take your business to the next level? Let app development do the work for you! With our team of experienced professionals, we will create a custom app tailored to your needs.',
    imgPath:
    '../../images/banner3.jpg',
  },

];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    // <Box sx={{ maxWidth: 100, flexGrow: 1 }}>
    <Box sx={{ flexGrow: 1 }} className="bannerWrap">
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          // height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        {/* <Typography>{images[activeStep].label}</Typography> */}
        <Container className="bannerTextWrap">
            <Grid container>
              <Grid item xs={6}>
                  <Typography variant='h3'>{images[activeStep].label}</Typography>
                  <Typography variant='h4' pb={2}>{images[activeStep].label2}</Typography>
                  <Typography variant='body2' pb={2}>{images[activeStep].label3}</Typography>
                  <Typography variant='body2'><Button size='large' variant='contained' endIcon={<EastIcon />}>Know more</Button></Typography>                           
              </Grid>
            </Grid>
        </Container>
        
        

      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  // height: 255,
                  display: 'block',
                  // maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;

