
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function CareersContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">Our <Typography variant="body">Careers</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
   
                       
                           {/* <Grid item sm={6}>
                           <Card className="abt_img"><img src="images/about_us2.jpg" variant="rounded" /></Card>                              
                                   
                           </Grid> */}
   
                           <Grid item sm={12}>                           
                               {/* <Typography variant="h6" p={0} sx={{display: 'flex'}}>
                                    <Typography variant="body" pr={2}><img src="images/icon.png" /></Typography>
                                    <Typography variant="body">About CHEAP WEB GURU</Typography>
                                </Typography> */}

                               {/* <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Cheap Web Guru is one of 
the best<Typography variant="body" className="yellowTxt" fontWeight="normal"> freelance web solutions
in India.</Typography></Typography> */}
                           <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >At Cheap Web Guru Solutions, we are always looking for talented and passionate professionals. We offer a wide range of career opportunities across a variety of disciplines, from engineering and business development to design and marketing.</Typography>

                           
                           </Grid>
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Our Job Opportunities</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/job-opportunities.png" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="body2" sx={{textAlign: 'justify'}}>We have a variety of job openings in our Digital Marketing, Web Development and App Development teams. Our team consists of experienced professionals in the fields of SEO, PPC, Content Marketing, Social Media, UX/UI Design, Web Development, Mobile App Development and more. To ensure that our teams stay at the forefront of the latest developments, we are always on the lookout for talented and innovative individuals.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Work Environment</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/work-environment.png"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="body2" sx={{textAlign: 'justify'}}>At our Cheap Web Guru Solutions, we strive to create an environment that is both inspiring and rewarding. We are committed to providing our team members with the tools and resources they need to succeed, while also supporting their personal and professional growth. Our team is diverse, collaborative and passionate about our work.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Benefits</Typography>
                                        <Typography variant="body2" pb={2} sx={{textAlign: 'justify'}}>We offer competitive salaries, bonus opportunities, and a comprehensive benefits package. Our benefits include health insurance, paid time off, and 401(k) plans. We also offer tuition reimbursement and other professional development opportunities.</Typography>
                                    </Grid>
                                  
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">How to Apply</Typography>
                                       <Typography variant="body2" pb={2} sx={{textAlign: 'justify'}}>If you are interested in applying for a position at Cheap Web Guru Solutions, please submit your resume and cover letter to info@cheapwebguru.com   {/*or simply fill the form below and attach your resume.  */}</Typography>
                                   
                                        <Typography variant="body2" pb={2} sx={{textAlign: 'justify'}}>We look forward to hearing from you!</Typography>
                                    </Grid>



                                </Grid>
                            </Grid>

                            {/* <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Vision</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/web_development.png" variant="rounded" width="100%"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="body2">Our vision is to become the go-to web and app development company for businesses of all sizes. We strive to make our services accessible, efficient and cost-effective for our clients, while maintaining the highest standards of quality. We are dedicated to staying ahead of the curve and innovating in the industry to keep our clients ahead of the competition.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default CareersContent;