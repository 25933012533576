
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function AppDevelopmenContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">App  <Typography variant="body">Development</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>

                        
                       <Grid item sm={12}>
                              <Typography variant="body2" py={2} >
                              Are you looking for a reliable partner for your mobile app development project? Look no further! We are a leading mobile app development company that has been helping businesses from around the world build innovative and engaging apps. As a full-service mobile app development agency, our team of experienced app developers is knowledgeable and passionate about creating apps that not only look great but also provide a great user experience.
                             </Typography>
                        </Grid>   
   
                       
                                                 
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                            <Grid item sm={12}>
                            <Typography variant="h4" fontWeight="bold" pb={2} className="blueTxt">What We Can Do for You</Typography>

                            <Typography variant="body2" pb={2}>
                            We specialize in developing custom mobile apps for a wide range of industries and platforms, including Android and iOS both. Whether you are looking to build an app from the ground up or need help with improving an existing app, our experienced app development team can help.<br /><br />
                            Our team of app developers and strategists have the expertise to develop apps to meet the specific needs of your business and help you reach your goals. We understand that each business is unique and requires a tailored approach. That's why we take the time to get to know your business, its goals, and the needs of your customers. From there, we develop a custom strategy that will help you achieve success.

                            </Typography>

                            
                            </Grid>


                            <Grid item sm={8}>
                            <Typography variant="h6" fontWeight="bold" pt={2}>We provide a range of services, including:</Typography>
                                <ul style={{ fontSize: 22, lineHeight: 1.8 }}>
                                    <li>Design and development of mobile apps</li>
                                    <li>UI/UX design</li>
                                    <li>Mobile app testing and optimization</li>
                                    <li>Maintenance and support</li>
                                    <li>Integration with existing systems</li>
                                    <li>API development</li>
                                    <li>Cloud-based app development</li>
                                </ul>
                            </Grid>
                            <Grid item sm={4}>
                            <img src="../../images/WebDevelopment3.jpg"  className="img_fluid_r"  />
                            </Grid>
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={12}>
                                <Grid container spacing={3}>
                                  
                                   
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Our Team</Typography>
                                        <Typography variant="body2" pb={2}>At our mobile app development agency, we strive to provide our clients with the highest quality apps. We use the latest technologies and tools to ensure that your app is secure, reliable, and easy to use. We also prioritize customer service, so you can be sure that you will receive the best possible support from our team.
                                        <br /><br />We understand that time is of the essence, which is why we are committed to delivering your project on time and within budget. We have a proven track record of delivering successful apps and have worked with clients in a variety of industries, including healthcare, retail, e-commerce, finance, and more.
</Typography>
                                        <Typography variant="h4" fontWeight="bold" py={1} className="blueTxt">Get in Touch</Typography>
                                        <Typography variant="body2" pb={2}>If you are interested in learning more about our app development services, please feel free to contact us. Our team would be happy to discuss your project in detail and provide you with a free quote.</Typography>

                                      


                                    </Grid>
                                </Grid>
                            </Grid>

                           
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default AppDevelopmenContent;